import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store";

import { Amplify, Auth } from "aws-amplify"; // amplifyライブラリのインポート
import awsExports from "./aws-exports"; // バックエンドの設定ファイルをインポート
import { Authenticator, View, Image, useTheme } from "@aws-amplify/ui-react"; // 認証UIコンポーネントをインポート
import "@aws-amplify/ui-react/styles.css"; // UIコンポーネントのcssをインポート
import { Header } from "./components/Header";
import { Main } from "./components/Main";

Amplify.configure(awsExports);
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "common-api",
        endpoint: process.env.REACT_APP_COMMON_API_ENDPOINT,
        custom_header: async () => {
          // return { Authorization : 'token' }
          // Alternatively, with Cognito User Pools use this:
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});

const components = {
  // Amplifyのコンポーネントをカスタマイズする
  Header() {
    // Amplifyのログイン画面のヘッダーをカスタマイズ
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="JWS logo"
          src="img/logo.jpg"
          maxWidth="150px"
          maxHeight="150px"
        />
      </View>
    );
  },
};

function App() {
  return (
    <React.StrictMode>
      <Authenticator components={components}>
        {() => (
          <Provider store={store}>
            <Header />
            <Main />
          </Provider>
        )}
      </Authenticator>
    </React.StrictMode>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
