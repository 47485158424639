import { Auth } from "aws-amplify";
//ログアウト時の実装
export const signOut = async () => {
  try {
    await Auth.signOut();
    window.location.reload();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};
