import { API } from "aws-amplify";
import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { createMock } from "./mock";

// let user = null;
let client = null;
let mock = null;

/**
 * 共通マスタAPIを実行する前に、axiosを初期化する
 * @param {string} token AWS Cognito認証用のトークン
 */
export const initJwsCommonApi = (token) => {
  client = axios.create({
    baseURL: process.env.REACT_APP_COMMON_API_ENDPOINT,
    headers: {
      Authorization: token,
    },
  });

  // モックを設定する
  if (process.env.REACT_APP_COMMON_API_MOCK_MODE === "1") {
    mock = new MockAdapter(client);
    createMock(mock);
  }
};

// const getToken = async () => {
//   // AWS Cognitoからトークンを取得する
//   user = await Auth.currentAuthenticatedUser();
// };
// getToken().then(() => {
//   client = axios.create({
//     baseURL: process.env.REACT_APP_COMMON_API_ENDPOINT,
//     headers: {
//       Authorization: user.signInUserSession.idToken.jwtToken,
//     },
//   });
//   mock = new MockAdapter(client);
//   createMock(mock);
// });

//---------- Amplify API version ----------//
export const getPersonsByEmail = async (email) => {
  return await API.get("common-api", `/persons?email=${email}`);
};

//---------- axios version ----------//
// export const getPersonsByEmail = async (email) => {
//   const testResponse = await client.get(`/persons?email=${email}`);
//   // const testResponse = await client.get(`/persons`);
//   console.log(JSON.stringify(testResponse), email);
//   return testResponse.data;
// };
